<template>
  <div>
    <t-modal
      :name="mid"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          $t('components.tripManagement.details.modals.policyPenalty.headline')
        }}
      </section>
      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            class="reason-form"
            @submit.prevent="handleSubmit(handleTrigger())"
            enctype="multipart/form-data"
          >
            <AppInput
              rules="required"
              v-model="models.reasonId"
              type="richselect"
              :name="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyReason.title'
                )
              "
              :label="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyReason.title'
                )
              "
              value-attribute="id"
              text-attribute="title"
              :placeholder="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyReason.selectReason'
                )
              "
              :options="penaltyReasons"
              hide-search-box
            />
            <div class="flex justify-items-start items-center mb-4">
              <label class="font-bold mr-4">{{
                $t(
                  'components.tripManagement.details.modals.policyPenalty.triggerType.title'
                )
              }}</label>
              <label class="cursor-pointer mr-4 flex items-center">
                <TRadioUnwrapped
                  v-model="models.penalty_type"
                  value="automatic"
                  :name="`penalty_type`"
                />

                <span class="ml-3 font-medium text-14px text-oDark">
                  {{
                    $t(
                      'components.tripManagement.details.modals.policyPenalty.triggerType.automatic'
                    )
                  }}
                </span>
              </label>
              <label class="cursor-pointer flex items-center">
                <TRadioUnwrapped
                  v-model="models.penalty_type"
                  value="custom"
                  :name="`penalty_type`"
                />

                <span class="ml-3 font-medium text-14px text-oDark">
                  {{
                    $t(
                      'components.tripManagement.details.modals.policyPenalty.triggerType.custom'
                    )
                  }}
                </span>
              </label>
            </div>

            <AppInput
              rules="required"
              type="number"
              step="0.01"
              :name="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyAmount.title'
                )
              "
              :label="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyAmount.title'
                )
              "
              :placeholder="
                models.penalty_type === 'automatic'
                  ? $t(
                      'components.tripManagement.details.modals.policyPenalty.penaltyAmount.selectPenaltyReason'
                    )
                  : $t(
                      'components.tripManagement.details.modals.policyPenalty.penaltyAmount.penaltyAmount'
                    )
              "
              :disabled="models.penalty_type === 'automatic' ? true : false"
              v-model="models.deduct_amount"
            />

            <AppInput
              v-model="models.remarks"
              rules=""
              :label="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.remarks'
                )
              "
              type="textarea"
              :name="
                $t(
                  'components.tripManagement.details.modals.policyPenalty.penaltyAmount.remarks'
                )
              "
            />
            <button type="submit" ref="triggerSubmitButtonRef" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>
      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          :disabled="isLoading"
          @click="hidePolicyPenaltyModal()"
        >
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :disabled="isLoading"
          :isLoading="isLoading"
          @click="$refs.triggerSubmitButtonRef.click()"
        >
          {{ $t('components.stepNavigation.apply') }}
        </AppButton>
      </section>
    </t-modal>
  </div>
</template>
<script>
import { TransactionConfig } from '@/config/TransactionConfig'
import { useEndpoints } from '@/composables'
export default {
  name: 'PolicyPenaltyPopup',
  props: {
    tripId: {
      type: String,
    },
    penaltyReasons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mid: TransactionConfig.events.policyPenaltyPopup,
      isLoading: false,
      models: {
        reasonId: null,
        deduct_amount: null,
        penalty_type: 'automatic',
        remarks: '',
      },
    }
  },
  watch: {
    'models.reasonId': {
      deep: false,
      immediate: false,
      handler(id) {
        this.models.deduct_amount = this.penaltyReasons.filter(
          (item) => item.id === id
        )[0].deduct_amount
      },
    },
    'models.penalty_type': {
      deep: false,
      immediate: false,
      handler() {
        this.models.deduct_amount = this.penaltyReasons.filter(
          (item) => item.id === this.models.reasonId
        )[0].deduct_amount
      },
    },
  },
  methods: {
    hidePolicyPenaltyModal() {
      this.$modal.hide(this.mid)
      this.models = {
        reasonId: null,
        deduct_amount: null,
        penalty_type: 'automatic',
        remarks: '',
      }
    },
    async handleTrigger() {
      this.isLoading = true
      console.log('handleTrigger', this.models, this.tripId)

      const url = useEndpoints.invoice.policyPenalty(
        this.tripId,
        this.models.reasonId
      )
      const data = new FormData()
      data.append('penalty_type', this.models.penalty_type)
      data.append('remarks', this.models.remarks)

      if (this.models.penalty_type === 'custom') {
        data.append('amount', parseFloat(this.models.deduct_amount))
      }

      await this.$http
        .post(url, data)
        .then((res) => {
          this.$emit('penalized')
          console.log('handleTrigger-s', res)
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: `${err.response.data.message}`,
            },
            5000
          )
        })
        .finally(() => {
          this.$modal.hide(this.mid)
          this.isLoading = false
          this.models = {
            reasonId: null,
            deduct_amount: null,
            penalty_type: 'automatic',
            remarks: '',
          }
        })
    },
  },
}
</script>
<style lang="scss"></style>
