var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{ref:"popup",attrs:{"name":_vm.mid,"variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.details.modals.policyPenalty.headline'))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"reason-form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleTrigger())}}},[_c('AppInput',{attrs:{"rules":"required","type":"richselect","name":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyReason.title'
              ),"label":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyReason.title'
              ),"value-attribute":"id","text-attribute":"title","placeholder":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyReason.selectReason'
              ),"options":_vm.penaltyReasons,"hide-search-box":""},model:{value:(_vm.models.reasonId),callback:function ($$v) {_vm.$set(_vm.models, "reasonId", $$v)},expression:"models.reasonId"}}),_c('div',{staticClass:"flex justify-items-start items-center mb-4"},[_c('label',{staticClass:"font-bold mr-4"},[_vm._v(_vm._s(_vm.$t( 'components.tripManagement.details.modals.policyPenalty.triggerType.title' )))]),_c('label',{staticClass:"cursor-pointer mr-4 flex items-center"},[_c('TRadioUnwrapped',{attrs:{"value":"automatic","name":"penalty_type"},model:{value:(_vm.models.penalty_type),callback:function ($$v) {_vm.$set(_vm.models, "penalty_type", $$v)},expression:"models.penalty_type"}}),_c('span',{staticClass:"ml-3 font-medium text-14px text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.tripManagement.details.modals.policyPenalty.triggerType.automatic' ))+" ")])],1),_c('label',{staticClass:"cursor-pointer flex items-center"},[_c('TRadioUnwrapped',{attrs:{"value":"custom","name":"penalty_type"},model:{value:(_vm.models.penalty_type),callback:function ($$v) {_vm.$set(_vm.models, "penalty_type", $$v)},expression:"models.penalty_type"}}),_c('span',{staticClass:"ml-3 font-medium text-14px text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.tripManagement.details.modals.policyPenalty.triggerType.custom' ))+" ")])],1)]),_c('AppInput',{attrs:{"rules":"required","type":"number","step":"0.01","name":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyAmount.title'
              ),"label":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyAmount.title'
              ),"placeholder":_vm.models.penalty_type === 'automatic'
                ? _vm.$t(
                    'components.tripManagement.details.modals.policyPenalty.penaltyAmount.selectPenaltyReason'
                  )
                : _vm.$t(
                    'components.tripManagement.details.modals.policyPenalty.penaltyAmount.penaltyAmount'
                  ),"disabled":_vm.models.penalty_type === 'automatic' ? true : false},model:{value:(_vm.models.deduct_amount),callback:function ($$v) {_vm.$set(_vm.models, "deduct_amount", $$v)},expression:"models.deduct_amount"}}),_c('AppInput',{attrs:{"rules":"","label":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.remarks'
              ),"type":"textarea","name":_vm.$t(
                'components.tripManagement.details.modals.policyPenalty.penaltyAmount.remarks'
              )},model:{value:(_vm.models.remarks),callback:function ($$v) {_vm.$set(_vm.models, "remarks", $$v)},expression:"models.remarks"}}),_c('button',{ref:"triggerSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.hidePolicyPenaltyModal()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","disabled":_vm.isLoading,"isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.triggerSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.apply'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }